<template>
    <section class="py-5 full-width" :class="{pt9:$route.name !== 'Home'}">
        <div class="container">
            <div class="row py-4 px-0 text-center" v-if="$route.name == 'Home'" data-aos="fade-up"
                 data-aos-duration="1200">
                <div class="col-12 mb-4 mb-lg-0 fl-x fl-j-c">
                    <h5 class="text-danger lt-gp-2">
                        RECENT PROJECT'S GALLERY
                    </h5>
                </div>
            </div>
            <div class="row mb-5" data-aos="fade-up" data-aos-duration="1500">
                <div class="col-12 fl-x fl-j-c">
                    <div class="pr-2 mr-lg-6 font-weight-bold lt-gp-1 cursorn-pointer afterFulWidth"
                         @click="setCateg('all')"
                         :class="{tabBtn:activeType === 'all' }">ALL
                    </div>
                    <div class="pr-2 mr-lg-6 font-weight-bold lt-gp-1 cursorn-pointer afterFulWidth"
                         @click="setCateg('exterior')"
                         :class="{tabBtn:activeType === 'exterior' }">EXTERIOR
                    </div>
                    <div class="pr-2 mr-lg-6 font-weight-bold lt-gp-1 cursorn-pointer afterFulWidth"
                         @click="setCateg('interior')"
                         :class="{tabBtn:activeType === 'interior' }">INTERIOR
                    </div>
                    <div class="font-weight-bold lt-gp-1 cursorn-pointer afterFulWidth" @click="setCateg('decoration')"
                         :class="{tabBtn:activeType === 'decoration' }">DECORATION
                    </div>
                </div>
            </div>
            <transition name="fade" mode="in-out">
                <div class="row mb-5">
                    <CoolLightBox
                        :items="imgItems"
                        :index="index"
                        @close="index = null">
                    </CoolLightBox>
                    <!--                <transition name="zoom" mode="out-in">-->
                    <template v-for="(i, imageIndex) in imgItems">
                        <transition name="zoom" mode="in-out" v-bind:key="imageIndex">

                            <div class="col-12 col-lg-4 p-0 m-0 img-box"
                                 @click="index = imageIndex"
                            >
                                <div class="gal-img-contain">
                                    <!--                                <img :src="require('@/assets/images/gallery/' + i.name)" alt="">-->
                                    <img :src="i" alt="" class="img-fluid">
                                    <div class="overlay-dark"></div>
                                </div>
                                <i class="fa fa-search-plus fa-2x photo-icon"></i>
                            </div>
                        </transition>
                    </template>
                </div>
            </transition>

            <div class="row" v-if="$route.name == 'Home'">
                <div class="col-12 text-center">
                    <router-link to="/gallery/" class="btn-plain text-semi-md">VIEW MORE</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
// import img1 from '../../assets/images/gallery/gal1.jpg';
// import img2 from '../../assets/images/gallery/gal2.jpg';
// import img3 from '../../assets/images/gallery/gal3.jpg';
// import img4 from '../../assets/images/gallery/gal4.jpg';
// import img5 from '../../assets/images/gallery/gal5.jpg';
// import img6 from '../../assets/images/gallery/gal6.jpg';
// import img7 from '../../assets/images/gallery/gal7.jpg';
// import img8 from '../../assets/images/gallery/gal8.jpg';
// import img9 from '../../assets/images/gallery/gal9.jpg';
// import img10 from '../../assets/images/gallery/gal10.jpg';
// import img11 from '../../assets/images/gallery/gal11.jpg';
// import img12 from '../../assets/images/gallery/gal12.jpg';
// import img13 from '../../assets/images/gallery/gal13.jpg';
// import img14 from '../../assets/images/gallery/gal14.jpg';
// import img15 from '../../assets/images/gallery/gal15.jpg';
// import img16 from '../../assets/images/gallery/gal16.jpg';
// import img17 from '../../assets/images/gallery/gal17.jpg';
// import img18 from '../../assets/images/gallery/gal18.jpg';
// import img19 from '../../assets/images/gallery/gal19.jpg';
// import img20 from '../../assets/images/gallery/gal20.jpg';
// import img21 from '../../assets/images/gallery/gal21.jpg';
// import img22 from '../../assets/images/gallery/gal22.jpg';
// import img23 from '../../assets/images/gallery/gal23.jpg';
// import img24 from '../../assets/images/gallery/gal24.jpg';
// import img25 from '../../assets/images/gallery/gal25.jpg';
// import img26 from '../../assets/images/gallery/gal26.jpg';
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    data () {
        return {
            activeType: 'all',
            listUrl: urls.gallery.list,
            siteUrl: urls.siteUrl,
            items: '', // filter to here
            imgItems: [],
            images: [],
            // images: [
            //     {
            //         type: 'deco',
            //         img: img1,
            //         // img: 'https://images.unsplash.com/photo-1612686635542-2244ed9f8ddc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            //         name: 'gal1.jpg'
            //     },
            //     { type: 'int', img: img2, name: 'gal2.jpg' },
            //     { type: 'ext', img: img3, name: 'gal3.jpg' },
            //     { type: 'ext', img: img4, name: 'gal4.jpg' },
            //     { type: 'deco', img: img5, name: 'gal5.jpg' },
            //     { type: 'int', img: img6, name: 'gal6.jpg' },
            //     { type: 'int', img: img7, name: 'gal7.jpg' },
            //     { type: 'int', img: img8, name: 'gal8.jpg' },
            //     { type: 'ext', img: img9, name: 'gal9.jpg' },
            //     { type: 'ext', img: img10, name: 'gal10.jpg' },
            //     { type: 'deco', img: img11, name: 'gal11.jpg' },
            //     { type: 'int', img: img12, name: 'gal12.jpg' },
            //     { type: 'int', img: img13, name: 'gal13.jpg' },
            //     { type: 'int', img: img14, name: 'gal14.jpg' },
            //     { type: 'int', img: img15, name: 'gal15.jpg' },
            //     { type: 'int', img: img16, name: 'gal16.jpg' },
            //     { type: 'int', img: img17, name: 'gal17.jpg' },
            //     { type: 'deco', img: img18, name: 'gal18.jpg' },
            //     { type: 'ext', img: img19, name: 'gal19.jpg' },
            //     { type: 'ext', img: img20, name: 'gal20.jpg' },
            //     { type: 'deco', img: img21, name: 'gal21.jpg' },
            //     { type: 'int', img: img22, name: 'gal22.jpg' },
            //     { type: 'int', img: img23, name: 'gal23.jpg' },
            //     { type: 'int', img: img24, name: 'gal24.jpg' },
            //     { type: 'int', img: img25, name: 'gal25.jpg' },
            //     { type: 'ext', img: img26, name: 'gal26.jpg' }
            //
            // ],
            index: null
        };
    },
    mounted () {
        console.log('images data');
        this.getImagesData();
        this.items = this.images;
        // this.imgItems = [];
        // this.images.forEach((item, i) => {
        //     if (this.$route.name === 'Home' && i > 5) {
        //         return false;
        //     }
        //     // this.imgItems.push(item.img);
        //     this.imgItems.push('https://redcarpet.xeoscript.com/upload/' + item.image);
        // });

        if (this.$route.name === 'Gallery') {
            console.log('entered gallery');
            const headerLinksEls = document.getElementsByClassName('header-link');
            console.log('header links', headerLinksEls);
            Array.from(headerLinksEls).forEach(function (linkEl) {
                if (!linkEl.classList.contains('home-link')) {
                    linkEl.classList.add('display-none');
                }
            });
        }
    },
    methods: {
        setCateg (categ) {
            this.activeType = String(categ).toLowerCase();
            console.log('categ', categ, typeof categ);
            if (categ === 'all') {
                this.items = this.images;
                this.imgItems = [];
                this.images.forEach((item, i) => {
                    console.log('image index', i);
                    if (this.$route.name === 'Home' && i > 5) {
                        return false;
                    }
                    this.imgItems.push('/upload/' + item.image);
                });
            } else {
                const itemsFiltered = this.images.filter(item => item.type === this.activeType);
                this.imgItems = [];
                itemsFiltered.forEach((item, i) => {
                    if (this.$route.name === 'Home' && i > 5) {
                        return false;
                    }
                    this.imgItems.push('/upload/' + item.image);
                    // this.imgItems.push(item.img);
                });
            }
        },
        async getImagesData () {
            // const response = await axios.get(this.listUrl);
            // const response = await axios.get('https://redcarpet.xeoscript.com/api/gallery/list');
            const response = await axios.get(this.listUrl);
            console.log(response.data.data);
            this.images = await response.data.data;
            // const responseData = response.json
            this.imgItems = [];
            this.images.forEach((item, i) => {
                if (this.$route.name === 'Home' && i > 5) {
                    return false;
                }
                // this.imgItems.push(item.img);
                this.imgItems.push(this.siteUrl + '/upload/' + item.image);
            });
        }
    }
};
</script>
<style lang="scss" scoped>

.img-box {
    position: relative;
    cursor: pointer;
    padding-bottom: 0 !important;

    .photo-icon {
        visibility: hidden;
        height: unset;
        display: none;
    }

    &:hover {
        .photo-icon {
            transition: all ease-in-out 300ms;
            position: absolute;
            top: 43%;
            left: 44%;
            bottom: 0;
            color: white;
            color: white;
            cursor: pointer;
            visibility: visible;
            height: unset;
            display: block;
        }

        .overlay-dark {
            visibility: visible;
        }
    }
}

.gal-img-contain {
    position: relative;
    height: 100% !important;

    img {
        width: 100%;
    }

    &:hover {

    }

}

.fade-left-enter-active, .fade-left-leave-active {
    transition: opacity .5s;
}

.fade-left-enter, .fade-left-leave-to /* .fade-left-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.fade-right-enter-active, .fade-right-leave-active {
    transition: opacity .5s;
}

.fade-right-enter, .fade-right-leave-to /* .fade-left-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.zoom-enter-active, .zoom-leave-active {
    transform: scale(1);
    opacity: 1;
    transition: all .5s;
}

.zoom-enter, .zoom-leave-to /* .fade-left-leave-active below version 2.1.8 */
{
    opacity: 0;
    transform: scale(0.6);
}

.afterFulWidth {
    transition: all 0.5s;

    &::after {
        width: 200%;
        opacity: 1;
        transition: all 0.5s;
    }

    &:hover {
        color: var(--color-danger);
    }
}

.mt9 {
    padding-top: 9rem;
}

.overlay-dark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.48);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
    bottom: 20 !important;
    visibility: hidden;
}
</style>
